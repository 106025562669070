<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">拼命加载中</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center">
          <div class="d-flex jc-center">
            <div class="title">
              <span class="title-text">数据可视化平台</span>
            </div>
          </div>
        </div>

        <div class="date-text">
          <span>{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span>
        </div>

        <el-button icon="el-icon-back" type="success" size="medium" circle class="backBtn" @click="handleBackBtn"></el-button>

        <div class="date-select">
          <el-date-picker
                  :picker-options="dateOptions"
                  :clearable="false"
                  v-model="dateValue"
                  value-format="yyyy-MM-dd"
                  type="date"
                  placeholder="请选择日期">
          </el-date-picker>
        </div>

<!--        <el-button @click="handlePlatform" type="primary" class="platformBtn">平台总监业绩</el-button>-->
        <div style="margin-top: 118px;margin-left: 19px; height: 865px;width: 1853px;">
          <el-collapse-transition>
            <div v-show="show">
              <div class="transition-box">
                <el-table
                        :header-cell-style="{
                          background:'#e2d2d2',
                          fontSize: '20px',
                          color: '#000'
                        }"
                        :data="tableData"
                        :cell-style="tableRowStyle"
                        style="width: 100%">
                  <el-table-column
                          prop="date"
                          align="center"
                          label="日期"
                          width="180">
                  </el-table-column>
                  <el-table-column
                          prop="name"
                          label="姓名"
                          align="center"
                          width="180">
                  </el-table-column>
                  <el-table-column
                          align="center"
                          prop="address"
                          label="地址">
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </el-collapse-transition>
        </div>

        <el-button type="primary" class="btnA" @click="platformBtn">平台任务进度</el-button>

        <el-button type="success" class="btnB" @click="liveBtn">直播任务进度</el-button>

        <el-button type="warning" class="btnC" @click="totalBtn">网校总任务进度</el-button>

        <div class="body-box">
          <!-- 第三行数据 -->
          <div class="content-box">
            <!--<div>
              <dv-border-box-12>
                <centerLeft1 />
              </dv-border-box-12>
            </div>
            <div>
              <dv-border-box-12>
                <centerLeft2 />
              </dv-border-box-12>
            </div>-->
            <!-- 中间 -->
            <div class="center-box" v-if="!show">
              <dv-border-box-8><center :selectedDate="dateValue" @func="getMsgFromChild"/></dv-border-box-8>
            </div>

<!--            <div class="center-bottom-a">-->
<!--              <dv-border-box-8><centerBottomA :selectedDate="dateValue"/></dv-border-box-8>-->
<!--            </div>-->

<!--            <div class="center-bottom-b">-->
<!--              <dv-border-box-8><centerBottomB/></dv-border-box-8>-->
<!--            </div>-->

<!--            <div class="center-bottom-c">-->
<!--              <dv-border-box-8><centerBottomC/></dv-border-box-8>-->
<!--            </div>-->

<!--            <div class="center-bottom-d">-->
<!--              <dv-border-box-8><centerBottomD/></dv-border-box-8>-->
<!--            </div>-->

            <div class="center-bottom-e" v-if="!show">
              <dv-border-box-8>
                <centerBottomE :selectedDate="dateValue" v-if="flag === '广告费'"/>
                <centerBottomF :selectedDate="dateValue" v-if="flag === '加V数量'"/>
                <centerBottomG :selectedDate="dateValue" v-if="flag === '直播间人数'"/>
                <centerBottomH :selectedDate="dateValue" v-if="flag === '直播业绩'"/>
                <centerBottomI :selectedDate="dateValue" v-if="flag === '平台业绩'"/>
                <centerBottomJ :selectedDate="dateValue" v-if="flag === '总业绩'"/>
              </dv-border-box-8>
            </div>

            <div class="left-a" v-if="!show">
              <dv-border-box-8>
                <leftA :selectedDate="dateValue"/>
              </dv-border-box-8>
            </div>

            <div class="left-b" v-if="!show">
              <dv-border-box-8>
                <leftB :selectedDate="dateValue"/>
              </dv-border-box-8>
            </div>

            <div class="right-a" v-if="!show">
              <dv-border-box-8>
                <rightA :selectedDate="dateValue"/>
              </dv-border-box-8>
            </div>

            <div class="right-b" v-if="!show">
              <dv-border-box-8>
                <rightB :selectedDate="dateValue"/>
              </dv-border-box-8>
            </div>

            <!-- 中间 -->
            <!--<div>
              <centerRight2 />
            </div>
            <div>
              <dv-border-box-13>
                <centerRight1 />
              </dv-border-box-13>
            </div>-->
          </div>

          <!-- 第四行数据 -->
          <!--<div class="bottom-box">
            <dv-border-box-13>
              <bottomLeft />
            </dv-border-box-13>
            <dv-border-box-12>
              <bottomRight />
            </dv-border-box-12>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../utils/drawMixin";
import { formatTime } from '../utils/index.js'
import centerLeft1 from './centerLeft1'
import centerLeft2 from './centerLeft2'
import centerRight1 from './centerRight1'
import centerRight2 from './centerRight2'
import center from './center'
import bottomLeft from './bottomLeft'
import bottomRight from './bottomRight'
import centerBottomA from './centerBottomA'
import centerBottomB from './centerBottomB'
import centerBottomC from './centerBottomC'
import centerBottomD from './centerBottomD'
import centerBottomE from './centerBottomE'
import centerBottomF from './centerBottomF'
import centerBottomG from './centerBottomG'
import centerBottomH from './centerBottomH'
import centerBottomI from './centerBottomI'
import centerBottomJ from './centerBottomJ'
import leftA from './leftA'
import leftB from './leftB'
import rightA from './rightA'
import rightB from './rightB'

export default {
  mixins: [ drawMixin ],
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      decorationColor: ['#568aea', '#000000'],
      dateValue: '',
      dateOptions: {
        disabledDate: this.getDisableDate
      },
      flag: '总业绩',
      show: false,
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1517 弄'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1519 弄'
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄'
      }]
    }
  },
  components: {
    centerLeft1,
    centerLeft2,
    centerRight1,
    centerRight2,
    center,
    bottomLeft,
    bottomRight,
    centerBottomA,
    centerBottomB,
    centerBottomC,
    centerBottomD,
    centerBottomE,
    centerBottomF,
    centerBottomG,
    centerBottomH,
    centerBottomI,
    centerBottomJ,
    leftA,
    leftB,
    rightA,
    rightB
  },
  mounted() {
    this.timeFn()
    this.cancelLoading()
    this.setDateDefault()
  },
  beforeDestroy () {
    clearInterval(this.timing)
  },
  methods: {
    handleBackBtn(){
      this.$router.push({name: 'table'})
    },
    platformBtn(){
      this.$router.push({name: 'platform'})
    },
    liveBtn(){
      this.$router.push({name: 'live'})
    },
    totalBtn(){
      this.$router.push({name: 'total'})
    },
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss')
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 100)
    },
    getDisableDate(date){
      return date.getTime() > new Date(this.dateDefault).getTime()
    },
    setDateDefault() {
      this.dateValue = this.dateDefault
    },
    getMsgFromChild(data){
      this.flag = data
    },
    handlePlatform(){
      this.show = !this.show
    },
    tableRowStyle({rowIndex}) {
      if (rowIndex % 2 === 0) {
        return 'background: oldlace;font-size: 18px'
      } else {
        return 'background: #f0f9eb;font-size: 18px'
      }
    }
  },
  computed: {
    dateDefault() {
      const date = new Date()
      const y = date.getFullYear()
      const m = date.getMonth() + 1
      const d = date.getDate() - 1
      return y + '-' + m + '-' + d
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';
.transition-box {
  width: 1853px;
  height: 865px;
  background-color: lightslategrey;
}
  .platformBtn {
    top: 60px;
    left: 300px;
    position: absolute;
    font-weight: bold;
    font-size: 15px;
  }

.btnA {
  position: absolute;
  top: 60px;
  left: 305px;
  font-weight: bold;
  font-size: 15px;
  }
.btnB {
  position: absolute;
  top: 60px;
  left: 425px;
  font-weight: bold;
  font-size: 15px;
}
.btnC {
  position: absolute;
  top: 60px;
  left: 555px;
  font-weight: bold;
  font-size: 15px;
}
  .backBtn{
    position: absolute;
    top: 58px;
    left: 30px;
  }
</style>