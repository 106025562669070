<template>
    <div>
        <h4>广告费</h4>
        <dv-scroll-ranking-board :config="config" style="width:370px;height:350px" class="content"/>
    </div>
</template>

<script>
    export default {
        props: ['selectedDate'],
        data(){
            return{
                config: {}
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            getData() {
                this.$http.get('/show/communityConsume?dateStr=' + this.selectedDate).then(({data}) => {
                    if (data.code === 0) {
                        this.config = {data: data.data.data}
                    } else {
                        this.config = {}
                    }
                })
            }
        },
        watch: {
            selectedDate() {
                this.config = {}
                this.getData()
            }
        }
    }
</script>

<style scoped>
    h4 {
        text-align: center;
        color: #ffeb7b;
        padding-top: 10px;
    }

    .content {
        padding-left: 20px;
        padding-bottom: 20px;
    }
</style>