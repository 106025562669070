<template>
    <div>
        <div ref="chart" class="echart"></div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                myChart: {}
            }
        },
        props: ['selectedDate'],
        mounted() {
            this.getEchartData()
        },
        watch:{
            selectedDate(){
                this.myChart.dispose()
                this.getEchartData()
            }
        },
        methods: {
            async getEchartData(){
                const { data:res } = await this.$http.get('/broadcast/achievement?dateStr='+this.selectedDate)
                if(res.code === 0){
                    const chart = this.$refs.chart
                    const myChart = this.$echarts.init(chart)
                    const option = {
                        title: {
                            text: '直播成交业绩',
                            left: 'center',
                            textStyle:{
                                color:'#fff',
                                fontSize: 20
                            }
                        },
                        tooltip: {
                            trigger: 'axis'
                        },
                        legend: {
                            data: ['白天直播', '晚上直播', '合计'],
                            left: '130',
                            top: '35',
                            textStyle:{
                                color:'#fff',
                                fontSize:15,
                                fontWeight:'bold'
                            }
                        },
                        grid: {
                            left: '3%',
                            right: '7%',
                            bottom: '6%',
                            top: '17%',
                            containLabel: true
                        },
                        xAxis: {
                            type: 'category',
                            boundaryGap: false,
                            data: res.data[0].list,
                            axisLabel:{
                                color: '#fff',
                                fontSize: 15,
                                fontWeight: 'bold',
                                rotate:-45
                            },
                            axisLine:{
                                lineStyle:{
                                    color:'#fff'
                                }
                            }
                        },
                        yAxis: {
                            type: 'value',
                            axisLabel:{
                                color: '#fff',
                                fontSize: 15,
                                fontWeight: 'bold'
                            }
                        },
                        series: [
                            {
                                name: '合计',
                                type: 'line',
                                stack: 'Total',
                                data: res.data[3].list
                            },
                            {
                                name: res.data[1].name,
                                type: 'line',
                                stack: 'Total',
                                data: res.data[1].list
                            },
                            {
                                name: res.data[2].name,
                                type: 'line',
                                stack: 'Total',
                                data: res.data[2].list
                            }
                        ]
                    }
                    myChart.setOption(option)
                    window.addEventListener('resize', function () {
                        myChart.resize
                    })
                    this.myChart = myChart
                }
            }
        }
    }
</script>

<style scoped>
    .echart {
        padding-top: 15px;
        width: 520px;
        height: 450px;
    }
</style>