<template>
    <div class="platform" ref="appRef">
        <div class="title">
            <span>平台任务进度</span>
        </div>
        <el-button icon="el-icon-back" type="success" size="medium" circle class="btn" @click="handleBack"></el-button>
        <el-button type="primary" class="btnB" @click="liveBtn">直播任务进度</el-button>
        <el-button type="warning" class="btnC" @click="totalBtn">网校总任务进度</el-button>
        <div class="content">
            <div ref="chart" class="chart"></div>
        </div>
    </div>
</template>

<script>
    import drawMixin from '../utils/drawMixin'
    export default {
        mixins: [ drawMixin ],
        data(){
            return{
                myChart: {}
            }
        },
        mounted() {
            this.getData()
        },
        methods:{
            handleBack(){
                this.$router.push({name: 'index'})
                this.myChart.dispose()
            },
            liveBtn(){
                this.$router.push({name: 'live'})
                this.myChart.dispose()
            },
            totalBtn(){
                this.$router.push({name: 'total'})
                this.myChart.dispose()
            },
            async getData(){
                const { data:res } = await this.$http.get('/platform/task')
                if(res.code === 0){
                    const chart = this.$refs.chart
                    const myChart = this.$echarts.init(chart)
                    const option = {
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'shadow'
                            },
                            formatter: function (params) {
                                const a1 = (params[0].value/10000).toFixed(2) + '万'
                                const a2 = (params[1].value/10000).toFixed(2) + '万'
                                const b1 = (params[3].value/10000).toFixed(2) + '万'
                                const b2 = (params[4].value/10000).toFixed(2) + '万'
                                const leader = res.data[7].data[params[0].dataIndex]
                                const percent = params[0].value === 0 ? '0%' : Math.round(params[1].value / params[0].value * 10000) / 100.00 + '%'
                                const uncomplete = (Math.abs(params[2].value)/10000).toFixed(2) + '万'
                                const saleUncomplete = (Math.abs(params[5].value)/10000).toFixed(2) + '万'
                                const salePercent = params[3].value === 0 ? '0%' : Math.round(params[4].value / params[3].value * 10000) / 100.00 + '%'
                                if(params[0].axisValue !== '北京'){
                                    return `
                                        ${params[0].axisValue}&nbsp;&nbsp;&nbsp;${leader}<br/>
                                        ${params[0].marker}${params[0].seriesName}&nbsp;&nbsp;&nbsp;${a1}<br/>
                                        ${params[1].marker}${params[1].seriesName}&nbsp;&nbsp;&nbsp;${a2}<br/>
                                        ${params[2].marker}${params[2].seriesName}&nbsp;&nbsp;&nbsp;${uncomplete}<br/>
                                        完成百分比&nbsp;&nbsp;&nbsp;${percent}
                                        `
                                }else {
                                    return `
                                        ${params[0].axisValue}&nbsp;&nbsp;&nbsp;${leader}<br/>
                                        ${params[0].marker}平台${params[0].seriesName}&nbsp;&nbsp;&nbsp;${a1}<br/>
                                        ${params[1].marker}平台${params[1].seriesName}&nbsp;&nbsp;&nbsp;${a2}<br/>
                                        ${params[2].marker}平台${params[2].seriesName}&nbsp;&nbsp;&nbsp;${uncomplete}<br/>
                                        平台完成百分比&nbsp;&nbsp;&nbsp;${percent}<br/>
                                        ${params[3].marker}${params[3].seriesName}&nbsp;&nbsp;&nbsp;${b1}<br/>
                                        ${params[4].marker}${params[4].seriesName}&nbsp;&nbsp;&nbsp;${b2}<br/>
                                        ${params[5].marker}${params[5].seriesName}&nbsp;&nbsp;&nbsp;${saleUncomplete}<br/>
                                        销售完成百分比&nbsp;&nbsp;&nbsp;${salePercent}<br/>
                                        `
                                }
                            }
                        },
                        legend: {
                            data: ['目标','实际完成','未完成'],
                            textStyle:{
                                color:'#fff',
                                fontSize:15,
                                fontWeight:'bold'
                            }
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        yAxis: [
                            {
                                type: 'value',
                                axisLabel:{
                                    color: '#fff',
                                    fontSize: 15,
                                    fontWeight: 'bold'
                                }
                            }
                        ],
                        xAxis: [
                            {
                                type: 'category',
                                axisTick: {
                                    show: true
                                },
                                data: res.data[0].data,
                                axisLabel:{
                                    color: '#fff',
                                    fontSize: 15,
                                    fontWeight: 'bold'
                                },
                                axisLine:{
                                    lineStyle:{
                                        color:'#fff'
                                    }
                                }
                            }
                        ],
                        series: [
                            {
                                name: res.data[2].name,
                                type: 'bar',
                                label: {
                                    show: false,
                                    position: 'inside'
                                },
                                stack: 'a',
                                data: res.data[2].data,
                                color: '#67C23A'
                            },
                            {
                                name: res.data[1].name,
                                type: 'bar',
                                label: {
                                    show: false
                                },
                                stack: 'b',
                                data: res.data[1].data,
                                color: '#E6A23C'
                            },
                            {
                                name: res.data[3].name,
                                type: 'bar',
                                label: {
                                    show: false,
                                    position: 'left'
                                },
                                stack: 'c',
                                data: res.data[3].data,
                                color: '#F56C6C'
                            },
                            {
                                name: res.data[5].name,
                                type: 'bar',
                                label: {
                                    show: false,
                                    position: 'left'
                                },
                                stack: 'a',
                                data: res.data[5].data,
                                color: '#C71585'
                            },
                            {
                                name: res.data[4].name,
                                type: 'bar',
                                label: {
                                    show: false,
                                    position: 'left'
                                },
                                stack: 'b',
                                data: res.data[4].data,
                                color: '#708090'
                            },
                            {
                                name: res.data[6].name,
                                type: 'bar',
                                label: {
                                    show: false,
                                    position: 'left'
                                },
                                stack: 'c',
                                data: res.data[6].data,
                                color: '#008B8B'
                            }
                        ]
                    }
                    myChart.setOption(option)
                    window.addEventListener('resize', function () {
                        myChart.resize
                    })
                    this.myChart = myChart
                }
            }
        }
    }
</script>

<style scoped>
    .btnB {
        position: absolute;
        top: 90px;
        right: 180px;
        font-weight: bold;
        font-size: 15px;
    }
    .btnC {
        position: absolute;
        top: 90px;
        right: 30px;
        font-weight: bold;
        font-size: 15px;
    }
    .platform {
        width: 1920px;
        height: 1080px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: left top;
    }
    .title {
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;
        top: 80px;
        font-size: 40px;
        color: #fff;
        font-weight: bold;
        height: 50px;
        line-height: 50px;
    }
    .btn {
        position: absolute;
        font-size: 15px;
        top: 30px;
        left: 30px;
        font-weight: bold;
    }
    .content {
        position: absolute;
        left: 100px;
        right: 100px;
        bottom: 100px;
        height: 75%;
    }
    .chart {
        padding-top: 10px;
        width: 100%;
        height: 100%;
    }
</style>