import Vue from 'vue'
import axios from 'axios'
import router from '@/router'

const http = axios.create({
  timeout: 1000 * 30,
  baseURL: 'https://app2.ydtxuexiao.com/data'
})

http.interceptors.request.use(config => {
  config.headers['token'] = Vue.cookie.get('token')
  return config
}, error => {
  return Promise.reject(error)
})

http.interceptors.response.use(response => {
  if (response.data.code === 401) {
    clearLoginInfo()
    router.push({ name: 'login' })
  }
  return response
}, error => {
  return Promise.reject(error)
})

function clearLoginInfo () {
  Vue.cookie.delete('token')
}

export default http
