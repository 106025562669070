<template>
    <div>
        <div ref="chart" class="echart"></div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                myChart: {}
            }
        },
        props: ['selectedDate'],
        mounted() {
            this.getEchartData()
        },
        watch:{
            selectedDate(){
                this.myChart.dispose()
                this.getEchartData()
            }
        },
        methods: {
            async getEchartData(){
                const { data:res } = await this.$http.get('/show/addV?dateStr='+this.selectedDate)
                if(res.code === 0){
                    const chart = this.$refs.chart
                    const myChart = this.$echarts.init(chart)
                    const option = {
                        title: {
                            text: '加V数量',
                            left: 'center',
                            textStyle:{
                                color:'#fff',
                                fontSize: 30,
                                fontWeight: 'bolder'
                            }
                        },
                        xAxis: {
                            type: 'category',
                            data: res.data.Xaxis,
                            axisLabel:{
                                color: '#fff',
                                fontSize: 15,
                                fontWeight: 'bold'
                            },
                            axisLine:{
                                lineStyle:{
                                    color:'#fff'
                                }
                            }
                        },
                        yAxis: {
                            type: 'value',
                            axisLabel:{
                                color: '#fff',
                                fontSize: 15,
                                fontWeight: 'bold'
                            }
                        },
                        series: [
                            {
                                data: res.data.Yaxis,
                                type: 'bar',
                                showBackground: true,
                                backgroundStyle: {
                                    color: 'rgba(180, 180, 180, 0.2)'
                                },
                                label: {
                                    show: true,
                                    position: 'top',
                                    color:'#ffeb7b',
                                    fontSize:15,
                                    fontWeight: 'bold'
                                },
                                itemStyle: {
                                    color: this.$echarts.graphic.LinearGradient(
                                        0,
                                        1,
                                        0,
                                        0,
                                        [
                                            {
                                                offset: 0,
                                                color: '#63FFFF'
                                            },
                                            {
                                                offset: 1,
                                                color: '#0054FF'
                                            }
                                        ],
                                        false
                                    )
                                }
                            }
                        ]
                    }
                    myChart.setOption(option)
                    window.addEventListener('resize', function () {
                        myChart.resize
                    })
                    this.myChart = myChart
                }
            }
        }
    }
</script>

<style scoped>
    .echart {
        padding-top: 15px;
        width: 820px;
        height: 600px;
    }
</style>