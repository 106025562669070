<template>
  <div id="center">
    <div class="up">
      <div
        class="bg-color-black item"
        v-for="item in titleItem"
        :key="item.title"
        @click="sendMsg(item.title)"
      >
        <p>{{ item.title }}</p>
        <div>
          <dv-digital-flop
            class="dv-dig-flop"
            :config="item.number"
          />
        </div>
      </div>
    </div>
    <!--<div class="down">
      <div class="ranking bg-color-black">
        <span>
          <icon name="chart-pie" class="text-icon"></icon>
        </span>
        <span class="fs-xl text mx-2 mb-1 pl-3">年度负责人组件达标榜</span>
        <dv-scroll-ranking-board class="dv-scr-rank-board mt-1" :config="ranking" />
      </div>
      <div class="percent">
        <div class="item bg-color-black">
          <span>今日任务通过率</span>
          <CenterChart
            :id="rate[0].id"
            :tips="rate[0].tips"
            :colorObj="rate[0].colorData"
          />
        </div>
        <div class="item bg-color-black">
          <span>今日任务达标率</span>
          <CenterChart
            :id="rate[1].id"
            :tips="rate[1].tips"
            :colorObj="rate[1].colorData"
          />
        </div>
        <div class="water">
          <dv-water-level-pond class="dv-wa-le-po" :config="water" />
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
import CenterChart from '@/components/echart/center/centerChartRate'

export default {
  props: ['selectedDate'],
  data() {
    return {
      titleItem: [],
      ranking: {
        data: [
          {
            name: '周口',
            value: 55
          },
          {
            name: '南阳',
            value: 120
          },
          {
            name: '西峡',
            value: 78
          },
          {
            name: '驻马店',
            value: 66
          },
          {
            name: '新乡',
            value: 80
          },
          {
            name: '新乡2',
            value: 80
          },
          {
            name: '新乡3',
            value: 80
          },
          {
            name: '新乡4',
            value: 80
          },
          {
            name: '新乡5',
            value: 80
          },
          {
            name: '新乡6',
            value: 80
          }
        ],
        carousel: 'single',
        unit: '人'
      },
      water: {
        data: [24, 45],
        shape: 'roundRect',
        formatter: '{value}%',
        waveNum: 3
      },
      // 通过率和达标率的组件复用数据
      rate: [
        {
          id: 'centerRate1',
          tips: 60,
          colorData: {
            textStyle: '#3fc0fb',
            series: {
              color: ['#00bcd44a', 'transparent'],
              dataColor: {
                normal: '#03a9f4',
                shadowColor: '#97e2f5'
              }
            }
          }
        },
        {
          id: 'centerRate2',
          tips: 40,
          colorData: {
            textStyle: '#67e0e3',
            series: {
              color: ['#faf3a378', 'transparent'],
              dataColor: {
                normal: '#ff9800',
                shadowColor: '#fcebad'
              }
            }
          }
        }
      ]
    }
  },
  components: {
    CenterChart
  },
  methods: {
    sendMsg(title){
      this.$emit('func',title)
    },
    fetchData(){
      this.$http.get('/show/summary?dateStr=' + this.selectedDate).then(({data}) => {
        if(data.code === 0){
          this.titleItem = [
            {
              title: '广告费',
              number: {
                number: [data.data.communityConsume],
                toFixed: 2,
                textAlign: 'center',
                content: '{nt}',
                style: {
                  fontSize: 35,
                  fill: '#ffeb7b',
                  fontWeight: 'bold'
                }
              }
            },
            {
              title: '加V数量',
              number: {
                number: [data.data.addV],
                textAlign: 'center',
                content: '{nt}',
                style: {
                  fontSize: 35,
                  fill: '#ffeb7b',
                  fontWeight: 'bold'
                }
              }
            },
            {
              title: '直播间人数',
              number: {
                number: [data.data.studioPeople],
                textAlign: 'center',
                content: '{nt}',
                style: {
                  fontSize: 35,
                  fill: '#ffeb7b',
                  fontWeight: 'bold'
                }
              }
            },
            {
              title: '直播业绩',
              number: {
                number: [data.data.todayAchievement],
                toFixed: 2,
                textAlign: 'center',
                content: '{nt}',
                style: {
                  fontSize: 35,
                  fill: '#ffeb7b',
                  fontWeight: 'bold'
                }
              }
            },
            {
              title: '平台业绩',
              number: {
                number: [data.data.deptTodayAchievement],
                toFixed: 2,
                textAlign: 'center',
                content: '{nt}',
                style: {
                  fontSize: 35,
                  fill: '#ffeb7b',
                  fontWeight: 'bold'
                }
              }
            },
            {
              title: '总业绩',
              number: {
                number: [data.data.allAchievement],
                toFixed: 2,
                textAlign: 'center',
                content: '{nt}',
                style: {
                  fontSize: 35,
                  fill: '#ffeb7b',
                  fontWeight: 'bold'
                }
              }
            }
          ]
        }else {
          this.titleItem = [
            {
              title: '广告费',
              number: {
                number: [],
                toFixed: 2,
                textAlign: 'center',
                content: '{nt}',
                style: {
                  fontSize: 35,
                  fill: '#ffeb7b',
                  fontWeight: 'bold'
                }
              }
            },
            {
              title: '加V数量',
              number: {
                number: [],
                textAlign: 'center',
                content: '{nt}',
                style: {
                  fontSize: 35,
                  fill: '#ffeb7b',
                  fontWeight: 'bold'
                }
              }
            },
            {
              title: '直播间人数',
              number: {
                number: [],
                textAlign: 'center',
                content: '{nt}',
                style: {
                  fontSize: 35,
                  fill: '#ffeb7b',
                  fontWeight: 'bold'
                }
              }
            },
            {
              title: '直播业绩',
              number: {
                number: [],
                toFixed: 2,
                textAlign: 'center',
                content: '{nt}',
                style: {
                  fontSize: 35,
                  fill: '#ffeb7b',
                  fontWeight: 'bold'
                }
              }
            },
            {
              title: '平台业绩',
              number: {
                number: [],
                toFixed: 2,
                textAlign: 'center',
                content: '{nt}',
                style: {
                  fontSize: 35,
                  fill: '#ffeb7b',
                  fontWeight: 'bold'
                }
              }
            },
            {
              title: '总业绩',
              number: {
                number: [],
                toFixed: 2,
                textAlign: 'center',
                content: '{nt}',
                style: {
                  fontSize: 35,
                  fill: '#ffeb7b',
                  fontWeight: 'bold'
                }
              }
            }
          ]
        }
      })
    }
  },
  mounted() {
    this.fetchData()
  },
  watch: {
    selectedDate(){
      this.fetchData()
    }
  }
}
</script>

<style lang="scss" scoped>
  p {
    font-size: 20px;
    text-align: center;
    font-weight: bold;
  }
#center {
  display: flex;
  flex-direction: column;
  .up {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      border-radius: 10px;
      padding-top: 20px;
      margin-top: 10px;
      width: 30%;
      height: 110px;
      .dv-dig-flop {
        width: 100%;
        height: 60px;
      }
    }
  }
/*  .down {
    padding: 6px 4px;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    height: 255px;
    justify-content: space-between;
    .bg-color-black {
      border-radius: 5px;
    }
    .ranking {
      padding: 10px;
      width: 59%;
      .dv-scr-rank-board {
        height: 225px;
      }
    }
    .percent {
      width: 40%;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 50%;
        height: 120px;
        span {
          margin-top: 8px;
          font-size: 14px;
          display: flex;
          justify-content: center;
        }
      }
      .water {
        width: 100%;
        .dv-wa-le-po {
          height: 120px;
        }
      }
    }
  }*/
}
</style>
