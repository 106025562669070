<template>
    <div>
        <div ref="chart" class="echart"></div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                myChart: {}
            }
        },
        props: ['selectedDate'],
        mounted() {
            this.getEchartData()
        },
        watch:{
            selectedDate(){
                this.myChart.dispose()
                this.getEchartData()
            }
        },
        methods: {
            async getEchartData(){
                const { data:res } = await this.$http.get('/broadcast/todayDeal?dateStr='+this.selectedDate)
                if(res.code === 0){
                    const chart = this.$refs.chart
                    const myChart = this.$echarts.init(chart)
                    const option = {
                        title: {
                            text: '直播成交单数',
                            left: 'center',
                            textStyle:{
                                color:'#fff',
                                fontSize: 20
                            }
                        },
                        tooltip: {
                            trigger: 'item'
                        },
                        legend: {
                            orient: 'vertical',
                            left: '20',
                            top: '50',
                            textStyle:{
                                color:'#fff',
                                fontSize:15,
                                fontWeight:'bold'
                            }
                        },
                        color: [
                            '#FF383B',
                            '#3DFF64',
                            '#6441FF',
                            '#23F4FF',
                            '#0B1E9B',
                            '#FF2AFD',
                            '#FFEEEE',
                            '#FFAC14',
                            '#A1C6FF',
                            '#FF919B',
                            '#559BFF'
                        ],
                        series: [
                            {
                                type: 'pie',
                                radius: ['50%', '65%'],
                                center: ['55%','50%'],
                                data: res.data,
                                label: {
                                    show: true,
                                    color:'#ffeb7b',
                                    fontSize:15,
                                    fontWeight: 'bold'
                                },
                                labelLine: {
                                    lineStyle: {
                                        color: '#ffeb7b'
                                    }
                                },
                                emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    }
                                }
                            }
                        ]
                    }
                    myChart.setOption(option)
                    window.addEventListener('resize', function () {
                        myChart.resize
                    })
                    this.myChart = myChart
                }
            }
        }
    }
</script>

<style scoped>
    .echart {
        padding-top: 15px;
        width: 520px;
        height: 400px;
    }
</style>
