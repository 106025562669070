<template>
    <div class="all" ref="appRef">
        <el-button icon="el-icon-back" type="success" size="medium" circle class="btnA" @click="handleBtnA"></el-button>
        <el-button icon="el-icon-right" type="success" size="medium" circle class="btnB" @click="handleBtnB"></el-button>
        <div class="date">
            <el-date-picker
                    :picker-options="dateOptions"
                    :clearable="false"
                    v-model="dateValue"
                    value-format="yyyy-MM-dd"
                    type="date">
            </el-date-picker>
        </div>
        <div class="center-box">
            <el-table
                    :header-cell-style="{
                        background:'#D3D3D3',
                        fontSize: '18px',
                        color: '#000',
                        fontWeight: 'normal'
                    }"
                    :cell-style="tableCellStyle"
                    :row-style="{
                        height: '60px'
                    }"
                    border
                    stripe
                    :data="tableData">
                <el-table-column
                        align="center"
                        label="校区">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.name !== '合计'" type="text" style="color: #33cabb;font-size: 16px;" @click="handleDetail(scope.row.id)">{{scope.row.name}}</el-button>
                        <span v-else type="text" style="color: red;font-size: 16px;">{{scope.row.name}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="communityConsume"
                        align="center"
                        label="广告费">
                </el-table-column>
                <el-table-column
                        prop="addV"
                        align="center"
                        label="加V数量">
                </el-table-column>
                <el-table-column
                        align="center"
                        prop="addVCost"
                        label="加v成本">
                </el-table-column>
                <el-table-column
                        align="center"
                        prop="studioPeople"
                        label="直播间人数">
                </el-table-column>
                <el-table-column
                        align="center"
                        prop="todayAchievement"
                        label="直播业绩">
                </el-table-column>
                <el-table-column
                        align="center"
                        prop="num"
                        label="平台资源">
                </el-table-column>
                <el-table-column
                        align="center"
                        prop="deptTodayAchievement"
                        label="平台业绩">
                </el-table-column>
                <el-table-column
                        align="center"
                        prop="allAchievement"
                        label="总业绩">
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
    import drawMixin from '../utils/drawMixin'
    export default {
        mixins: [ drawMixin ],
        data() {
            return {
                tableData: [],
                dateOptions: {
                    disabledDate: this.getDisableDate
                },
                dateValue: ''
            }
        },
        mounted() {
            this.getTableData()
            this.setDateDefault()
        },
        methods: {
            async getTableData(){
                const { data:res } = await this.$http.get('/statistics/list?dateStr=' + this.dateValue)
                if(res.code === 0){
                    this.tableData = res.data
                }else {
                    this.tableData = []
                }
            },
            tableCellStyle(row){
                if(row.row.name === '合计'){
                    return 'font-size: 16px;color: red;'
                }else {
                    return 'font-size: 16px;color: black;'
                }
            },
            getDisableDate(date){
                return date.getTime() > new Date(this.dateDefault).getTime()
            },
            setDateDefault() {
                this.dateValue = this.dateDefault
            },
            handleBtnA(){
                this.$router.push({name: 'school'})
            },
            handleBtnB(){
                this.$router.push({name: 'index'})
            },
            handleDetail(id){
                this.$router.push({name: 'tableDetail',query:{id: id,dateValue: this.dateValue}})
            }
        },
        computed: {
            dateDefault() {
                const date = new Date()
                const y = date.getFullYear()
                const m = date.getMonth() + 1
                const d = date.getDate() - 1
                return y + '-' + m + '-' + d
            }
        },
        watch:{
            dateValue(){
                this.getTableData()
            }
        }
    }
</script>

<style scoped>
    .all {
        width: 1920px;
        height: 1080px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: left top;
    }
    .center-box {
        position: absolute;
        left: 100px;
        right: 100px;
        top: 130px;
        bottom: 100px;
    }
    .date {
        position: absolute;
        top:50px;
        left: 100px;
    }
    .btnA {
        position: absolute;
        top: 48px;
        left: 30px;
    }
    .btnB {
        position: absolute;
        top: 48px;
        right: 30px;
    }
</style>