import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import dataV from '@jiaminghi/data-view';
// 引入全局css
import './assets/scss/style.scss';
// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';

import { DatePicker,Button,Table,TableColumn,Card,Row,Col,Alert } from "element-ui";
import 'element-ui/lib/theme-chalk/base.css';
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';

//引入echart
//4.x 引用方式
// import echarts from 'echarts'
import './assets/css/date.css'

import httpRequest from '@/utils/httpRequest';

import VueParticles from 'vue-particles'
import Vant from 'vant';
import 'vant/lib/index.css';

import VueCookie from 'vue-cookie'
Vue.use(VueCookie);

Vue.use(VueParticles)
Vue.use(Vant);

Vue.prototype.$http = httpRequest

Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 };

Vue.use(DatePicker)
Vue.use(Button)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Card)
Vue.use(Row)
Vue.use(Col)
Vue.use(Alert)
Vue.component(CollapseTransition.name, CollapseTransition)

//5.x 引用方式为按需引用
//希望使用5.x版本的话,需要在package.json中更新版本号,并切换引用方式
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false;

// 全局注册
Vue.component('icon', Icon);
Vue.use(dataV);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
