<template>
    <div>
        <h4>总业绩</h4>
        <dv-scroll-ranking-board :config="config" style="width:370px;height:350px" class="content"/>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                config: {
                    data: [
                        {
                            name: '周口',
                            value: 55
                        },
                        {
                            name: '南阳',
                            value: 120
                        },
                        {
                            name: '西峡',
                            value: 78
                        },
                        {
                            name: '驻马店',
                            value: 66
                        },
                        {
                            name: '新乡',
                            value: 80
                        },
                        {
                            name: '信阳',
                            value: 45
                        },
                        {
                            name: '漯河',
                            value: 29
                        }
                    ]
                }
            }
        }
    }
</script>

<style scoped>
    h4 {
        text-align: center;
        color: #ffeb7b;
        padding-top: 10px;
    }

    .content {
        padding-left: 20px;
        padding-bottom: 20px;
    }
</style>