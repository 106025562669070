import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login'
import index from '../views/index'
import platform from '../views/platform'
import live from '../views/live'
import total from '../views/total'
import School from '../views/School'
import Table from '../views/Table'
import TableDetail from '../views/TableDetail'

Vue.use(VueRouter)

const routes = [
  { path: '/',redirect: '/index' },
  { path: '/login', component: login, name: 'login' },
  { path: '/index', component: index, name: 'index' ,meta: { requireAuth: true }},
  { path: '/platform', component: platform, name: 'platform' ,meta: { requireAuth: true }},
  { path: '/live', component: live, name: 'live' ,meta: { requireAuth: true }},
  { path: '/total', component: total, name: 'total' ,meta: { requireAuth: true }},
  { path: '/school', component: School, name: 'school' ,meta: { requireAuth: true }},
  { path: '/table', component: Table, name: 'table' ,meta: { requireAuth: true }},
  { path: '/tableDetail', component: TableDetail, name: 'tableDetail' ,meta: { requireAuth: true }},
  { path: '*', redirect: '/index'}
]
const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((auth) => auth.meta.requireAuth)) {
    let token = Vue.cookie.get('token');
    if (token) {
      next();
    } else {
      next({
        path: "/login"
      });
    }
  } else {
    next();
  }
})

export default router