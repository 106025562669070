<template>
    <div class="all" ref="appRef">
        <el-button icon="el-icon-back" type="success" size="medium" circle class="btnA" @click="handleBtnA"></el-button>
        <el-button icon="el-icon-s-data" type="success" size="medium" circle class="btnB" @click="handleBtnB"></el-button>
        <div class="date">
            <el-date-picker
                    :picker-options="dateOptions"
                    :clearable="false"
                    v-model="dateValue"
                    value-format="yyyy-MM-dd"
                    type="date">
            </el-date-picker>
        </div>
        <div class="table">
            <el-table
                    :header-cell-style="{
                        background:'#D3D3D3',
                        fontSize: '18px',
                        color: '#000',
                        fontWeight: 'normal'
                    }"
                    :cell-style="tableCellStyle"
                    border
                    stripe
                    :data="tableData"
                    height="820">
                <el-table-column
                        label="日期"
                        align="center"
                        prop="dateStr">
                </el-table-column>
                <el-table-column
                        label="校区"
                        align="center"
                        prop="name">
                </el-table-column>
                <el-table-column
                        label="广告费"
                        align="center"
                        prop="communityConsume">
                </el-table-column>
                <el-table-column
                        label="加V数量"
                        align="center"
                        prop="addV">
                </el-table-column>
                <el-table-column
                        label="加v成本"
                        align="center"
                        prop="addVCost">
                </el-table-column>
                <el-table-column
                        label="直播间人数"
                        align="center"
                        prop="studioPeople">
                </el-table-column>
                <el-table-column
                        label="直播业绩"
                        align="center"
                        prop="todayAchievement">
                </el-table-column>
                <el-table-column
                        label="平台资源"
                        align="center"
                        prop="num">
                </el-table-column>
                <el-table-column
                        label="平台业绩"
                        align="center"
                        prop="deptTodayAchievement">
                </el-table-column>
                <el-table-column
                        label="总业绩"
                        align="center"
                        prop="allAchievement">
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
    import drawMixin from '../utils/drawMixin'
    export default {
        mixins: [ drawMixin ],
        data(){
            return{
                id: '',
                dateValue: '',
                tableData: [],
                dateOptions: {
                    disabledDate: this.getDisableDate
                }
            }
        },
        mounted() {
            this.id = this.$route.query.id
            this.dateValue = this.$route.query.dateValue
            this.getData()
        },
        methods: {
            async getData(){
                const { data: res } = await this.$http.get(`/statistics/detailList?dateStr=${this.dateValue}&areaId=${this.id}`)
                if(res.code === 0){
                    this.tableData = res.data
                }else {
                    this.tableData = []
                }
            },
            tableCellStyle(row){
                if(row.row.dateStr === '合计'){
                    return 'font-size: 16px;color: red;'
                }else {
                    return 'font-size: 16px;color: black;'
                }
            },
            handleBtnA(){
                this.$router.push({name: 'table'})
            },
            handleBtnB(){
                this.$router.push({name: 'index'})
            },
            getDisableDate(date){
                return date.getTime() > new Date(this.dateDefault).getTime()
            }
        },
        computed: {
            dateDefault() {
                const date = new Date()
                const y = date.getFullYear()
                const m = date.getMonth() + 1
                const d = date.getDate() - 1
                return y + '-' + m + '-' + d
            }
        },
        watch:{
            dateValue(){
                this.getData()
            }
        }
    }
</script>

<style scoped>
    .all {
        width: 1920px;
        height: 1080px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: left top;
    }
    .table {
        position: absolute;
        left: 100px;
        top: 130px;
        right: 100px;
    }
    .btnA {
        position: absolute;
        top: 48px;
        left: 30px;
    }
    .btnB {
        position: absolute;
        top: 48px;
        right: 30px;
    }
    .date {
        position: absolute;
        top:50px;
        left: 100px;
    }
</style>