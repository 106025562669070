<template>
    <div>
        <h3 class="titleClass">平台成交业绩</h3>
        <dv-capsule-chart :config="config" style="width:480px;height:400px" class="contentClass"/>
    </div>
</template>

<script>
    export default {
        props: ['selectedDate'],
        data() {
            return {
                config: {}
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            async getData() {
                const { data : res } = await this.$http.get('/platform/totalAchievement?dateStr=' + this.selectedDate)
                if (res.code === 0) {
                    this.config = {
                        data: res.data,
                        unit: '单位',
                        showValue: true
                    }
                }
            }
        },
        watch: {
            selectedDate() {
                this.getData()
            }
        }
    }
</script>

<style scoped>
    h3 {
        text-align: center;
        color: #fff;
        padding-top: 15px;
        font-size: 20px;
    }
    .contentClass {
        padding-left: 30px;
        padding-top: 20px;
    }
</style>