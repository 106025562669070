<template>
    <div class="content" ref="appRef">
        <div class="content-box">
            <vue-particles
                    class="login-bg"
                    color="#f4f4f4"
                    :particleOpacity="0.7"
                    :particlesNumber="100"
                    shapeType="circle"
                    :particleSize="4"
                    linesColor="#f4f4f4"
                    :linesWidth="1"
                    :lineLinked="true"
                    :lineOpacity="0.4"
                    :linesDistance="150"
                    :moveSpeed="3"
                    :hoverEffect="true"
                    hoverMode="grab"
                    :clickEffect="true"
                    clickMode="push"
            />
            <div class="content-login">
                <div class="content-login-info">
                    <div class="content-title">Login</div>
                    <van-form ref="loginForm">
                        <div style="margin:25px 0px">
                        </div>
                        <van-field
                                style="width: 300px;left: 0;right: 0;margin: auto"
                                v-model="password"
                                type="password"
                                name="密码"
                                label="密码"
                                placeholder="密码"
                                :rules="[{ required: true, message: '请填写密码' }]"
                        />
                        <div style="margin: 26px;">
                            <van-button round block type="primary" @keyup.enter.native="loginBtn" @click="loginBtn">登录</van-button>
                        </div>
                    </van-form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import drawMixin from '../utils/drawMixin'
    export default {
        mixins: [ drawMixin ],
        data(){
            return {
                username: 'admin',
                password: '',
                loginData:[]
            }
        },
        methods:{
            loginBtn() {
                this.$refs.loginForm.validate().then(()=>{
                    this.$http.post('/login',{ username: this.username,password: this.password }).then(({data}) => {
                        if(data.code === 0){
                            this.$toast.success('登录成功')
                            this.$cookie.set('token',data.data.token)
                            this.$router.replace({name: 'school'})
                            document.documentElement.requestFullscreen();
                            localStorage.setItem('password', this.password);
                        }else {
                            this.$toast.fail(data.message)
                        }
                    })
                })
            },
            toregister(){
                this.$router.push({name:'register'})
            },
            topassword(){
                this.$toast.fail('功能暂未开放!')
            }
        },
        mounted() {
            if(localStorage.getItem('password') !== null){
                this.password = localStorage.getItem('password')
            }
        }
    }
</script>
<style scoped>
    .content{
        position: absolute;
        width: 1920px;
        height: 1080px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: left top;
        overflow: hidden;
        /*background-image: url('../assets/bg.jfif');*/
        background-size:cover ;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .login-bg{
        position:absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .content-title{
        text-align: center;
        font-size:25px;
        color:#fff
    }
    .content-login{
        transform: translate(-50%,-50%);
        position: fixed;
        width: 400px;
        height: 320px;
        background: rgba(223,219,219,0.2);
        display: flex;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
        box-shadow: 0 25px 35px rgba(0,0,0,0.8);
    }
    .content-login-info{
        width: 90%;
    }
    .content-bottom{
        display: flex;
        justify-content: space-between;
        color: blue;
        font-size:14px
    }
    .content-bottom :hover{
        cursor: pointer;
    }
</style>