var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"appRef",staticClass:"all"},[_c('el-button',{staticClass:"btnA",attrs:{"icon":"el-icon-back","type":"success","size":"medium","circle":""},on:{"click":_vm.handleBtnA}}),_c('el-button',{staticClass:"btnB",attrs:{"icon":"el-icon-right","type":"success","size":"medium","circle":""},on:{"click":_vm.handleBtnB}}),_c('div',{staticClass:"date"},[_c('el-date-picker',{attrs:{"picker-options":_vm.dateOptions,"clearable":false,"value-format":"yyyy-MM-dd","type":"date"},model:{value:(_vm.dateValue),callback:function ($$v) {_vm.dateValue=$$v},expression:"dateValue"}})],1),_c('div',{staticClass:"center-box"},[_c('el-table',{attrs:{"header-cell-style":{
                    background:'#D3D3D3',
                    fontSize: '18px',
                    color: '#000',
                    fontWeight: 'normal'
                },"cell-style":_vm.tableCellStyle,"row-style":{
                    height: '60px'
                },"border":"","stripe":"","data":_vm.tableData}},[_c('el-table-column',{attrs:{"align":"center","label":"校区"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.name !== '合计')?_c('el-button',{staticStyle:{"color":"#33cabb","font-size":"16px"},attrs:{"type":"text"},on:{"click":function($event){return _vm.handleDetail(scope.row.id)}}},[_vm._v(_vm._s(scope.row.name))]):_c('span',{staticStyle:{"color":"red","font-size":"16px"},attrs:{"type":"text"}},[_vm._v(_vm._s(scope.row.name))])]}}])}),_c('el-table-column',{attrs:{"prop":"communityConsume","align":"center","label":"广告费"}}),_c('el-table-column',{attrs:{"prop":"addV","align":"center","label":"加V数量"}}),_c('el-table-column',{attrs:{"align":"center","prop":"addVCost","label":"加v成本"}}),_c('el-table-column',{attrs:{"align":"center","prop":"studioPeople","label":"直播间人数"}}),_c('el-table-column',{attrs:{"align":"center","prop":"todayAchievement","label":"直播业绩"}}),_c('el-table-column',{attrs:{"align":"center","prop":"num","label":"平台资源"}}),_c('el-table-column',{attrs:{"align":"center","prop":"deptTodayAchievement","label":"平台业绩"}}),_c('el-table-column',{attrs:{"align":"center","prop":"allAchievement","label":"总业绩"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }