<template>
    <div class="all" ref="appRef">
        <el-button icon="el-icon-right" type="success" size="medium" circle class="btnA" @click="handleBtnA"></el-button>
        <el-button icon="el-icon-s-data" type="primary" size="medium" circle class="btnB" @click="handleBtnB"></el-button>
        <div style="margin-left: 30px;margin-top: 100px;">
            <div v-for="item in dataList" :key="item.id" class="body" @click="handleCard">
                <el-card style="height: 270px;background-color: #33cabb;margin-right: 10px;margin-left: 20px;margin-bottom: 30px;width: 340px;">
                    <div slot="header" style="text-align: center;">
                        <h2 style="color:#fff;font-size: 1.8rem">{{item.name}}</h2>
                    </div>
                    <div>
                        <p>{{item.schoolMaster}}</p>
                        <p>{{item.platformMaster}}</p>
                        <p>{{item.broadcastMaster}}</p>
                        <p>{{item.communityMaster}}</p>
                    </div>
                </el-card>
            </div>
        </div>
    </div>
</template>

<script>
    import drawMixin from '../utils/drawMixin'
    export default {
        mixins: [ drawMixin ],
        data(){
          return {
              dataList: []
          }
        },
        methods: {
            handleCard(){
                this.$router.push({name: 'table'})
            },
            handleBtnA(){
                this.$router.push({name: 'table'})
            },
            handleBtnB(){
                this.$router.push({name: 'index'})
            },
            async getData(){
                const { data:res } = await this.$http.get('/views/campus')
                if(res.code === 0){
                    this.dataList = res.data
                }else {
                    this.dataList = []
                }
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style scoped>
    .all {
        width: 1920px;
        height: 1080px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: left top;
    }
    .btnA {
        position: absolute;
        top: 30px;
        right: 30px;
    }
    .btnB {
        position: absolute;
        top: 30px;
        right: 80px;
    }
    p{
        font-size: 22px;
        margin-left: 15px;
        margin-bottom: 20px;
        color: #fff;
    }
    .body {
        display: inline-block;
        cursor: pointer;
    }
</style>